
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Inqueryform() {

  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  const [status, setStatus] = useState("1");
  const [countryid, setCountryid] = useState();
  const [passport, setPassport] = useState();
  const [qualification, setQualification] = useState();
  const [ielts, setIelts] = useState();

  const [countrylist, setCountrylist] = useState([]);
  const handleChangecountry = (event) => {
        setCountryid(event.target.value);
  };

  const handleChangequalification = (event) => {
        setQualification(event.target.value);
  };

  const handleChangepassport = (event) => {
        setPassport(event.target.value);
  };

  const handleChangeielts = (event) => {
        setIelts(event.target.value);
  };

  const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget);
        formData.append('status', status);
        formData.append('countryid', countryid);
        formData.append('passport', passport);
        formData.append('qualification', qualification);
        formData.append('ielts', ielts);

        axios.post('/api/studentregistration/add', formData).then(function(response){
            
            console.log(response);
            if(response.data.errors){
                toast(response.data.message);
            }else{
                toast("Apply Successful");
            }

           // navigate("/app/dashboard"); 
        }).catch(function (error) {
            console.log(error.message);
            toast("hello");
        });
    }


    useEffect(() => {
          axios.get('/api/country')
          .then(response => {

            console.log(response);
            setCountrylist(response.data.data.data);
          })
          .catch(error => {
              console.error(error);
          });
          
      }, []);

  return (
           
    <div className="register-area bg-img pt-130 pb-130" style={{backgroundImage: 'url(assets/img/background4.jpg)'}}>
        <div className="container">
          <div className="section-title-2 mb-75 white-text">
            <h2><span>Apply Now</span></h2>
            <p>Book your seat, open your file with us & grab amazing gift.</p>
          </div>
          <ToastContainer />
          <div className="register-wrap">
            <div className="row">
              <div className="col-lg-10 col-md-8">
                <div className="register-form">
                  <h4>Get A free Apply</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="contact-form-style mb-20">
                          <input name="name" placeholder="Full Name" type="text" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contact-form-style mb-20">
                          <input name="dob" placeholder="Date of Birth" type="date" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                         <div className="billing-info-wrap" style={{background:"#ffffff"}}>
                          <div className="billing-select mb-20"> 
                          <select name="countryid" value={countryid} onChange={handleChangecountry}>
                            <option>Which country you want to apply ?</option>
                            {countrylist?.map((country) => (
                              <option value={country.id}>{country._name}</option>
                            ))}  
                           </select>
                          </div> 
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="billing-info-wrap" style={{background:"#ffffff"}}>
                          <div className="billing-select mb-20">  
                            <select name="qualification" onChange={handleChangequalification}>
                              <option>Last academic qualification</option>
                              <option value="SSC">SSC / O LEVEL</option>
                              <option value="HSC">HSC / A LEVEL</option>
                              <option value="BACHELOR">BACHELOR</option>
                              <option value="MASTERS">MASTERS</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="billing-info-wrap" style={{background:"#ffffff"}}>
                          <div className="billing-select mb-20">  
                            <select name="ielts" value={ielts} onChange={handleChangeielts}>
                              <option>Have IELTS / PTE ?</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="billing-info-wrap" style={{background:"#ffffff"}}>
                          <div className="billing-select mb-20">  
                            <select name="passport" value={passport} onChange={handleChangepassport}>
                              <option>Passport availability ?</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contact-form-style mb-20">
                          <input name="mobile" placeholder="Mobile" type="text" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contact-form-style mb-20">
                          <input name="email" placeholder="Email" type="text" />
                        </div>
                      </div>
                      <div className="col-lg-12 mt-20">
                        <div className="row">
                          <div className="col-lg-1">
                            <input value="1" name="agree" required type="checkbox" style={{width:"20px"}}/>
                          </div>
                          <div className="col-lg-11">
                          <p style={{color:"#ffffff"}}> <span>I hereby declare that :
All information and materials given in this form are true and correct. Edumatric will not be liable or responsible for any information provided by me.</span></p>
                      
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-form-style">
                          <button className="submit default-btn" type="submit">SUBMIT NOW</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="register-1" className="mouse-bg" />
        <div id="register-2" className="mouse-bg" />
      </div>
  );
}
export default Inqueryform;


