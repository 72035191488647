
import React, { useState, useEffect } from 'react';

import Layout from "../include/Layout";
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link ,useParams} from 'react-router-dom';
import Breadcrumb from "../allcomponent/Breadcrumb";
import Client from "../allcomponent/Client";
import Achivement from "../allcomponent/Achivement";
function Universitypage() {
    const params = useParams();
    const [title, setTitle] = useState("University");
    const [subtitle, setSubtitle] = useState("We are working 2000+ university all over the world.");
    
    axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
    const [universitylist, setUniversitylist] = useState();
    useEffect(() => {
        axios.get(`/api/universitylist/${params.id}`)
        .then(response => {
          setUniversitylist(response.data.data); 
        })
        .catch(error => {
            console.error(error);
        });
        window.scrollTo(0, 0);
        
    }, []);

    const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 6,
            },
        },
    }

  return (  
    <Layout>
    <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
      <div className="course-area bg-img pt-90">
        <div className="container">
          <div className="section-title mb-70">
            <h2>
              <span>Choose Your University in {params.country}</span>
            </h2>
            <p>We are working with 2000+ universities of 20+ countries. </p>
          </div>
          <div className="course-slider-active nav-style-1 universitycourse">
            { universitylist && 
            <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
            {universitylist?.map((university) => (
              <div className="single-course item">
                <div className="course-img">
                  <Link to={`/university/${university.id}/${university._name}`}>
                    <img className="animated" src={university._image} alt="" style={{padding:"2rem",height:"200px",width:"200px",margin:"0 auto"}}/>
                  </Link>
                </div>
                <div className="course-content univeristylogo">
                  <b>
                    <Link to={`/university/${university.id}`}>{university._name}</Link>
                  </b>
                </div>
              </div>
              ))}
            </OwlCarousel>
            }
          </div>
        </div>
      </div>
      <Achivement></Achivement>
              <Client></Client>
    </Layout> 
  );
}
export default Universitypage;


