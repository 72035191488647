
import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./components/page/Home";
import Contact from "./components/page/Contact";
import Courses from "./components/page/Courses";
import About from "./components/page/Aboutus";
import Eventpage from "./components/page/Eventpage";
import Coursedetails from "./components/page/Coursedetails";
import Universitypage from "./components/page/Universitypage";
import Universitycourse from "./components/page/Universitycourses";
import Applyform from "./components/page/Applyform";
import Registration from "./components/page/Registration";
import axios from 'axios';
function App() {

  return (
    <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/courses" element={<Courses/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/event" element={<Eventpage/>}></Route>
        <Route path="/coursedetails/:id" element={<Coursedetails/>}></Route>
        <Route path="/country/:id/:country" element={<Universitypage/>}></Route>
        <Route path="/university/:id/:universityname" element={<Universitycourse/>}></Route>
        <Route path="/applyform" element={<Applyform/>}></Route>
        <Route path="/registration" element={<Registration/>}></Route>
    </Routes>
  )
}

export default App;

