import { useState,useEffect,React } from "react";
import axios from 'axios';

import Layout from "../include/Layout";
import Slider from "../allcomponent/Slider";
import Facilities from "../allcomponent/Facilities";
import About from "../allcomponent/About";
import Course from "../allcomponent/Course";
import Achivement from "../allcomponent/Achivement";
import Registrationform from "../allcomponent/Registrationform";
import Event from "../allcomponent/Event";
import Newsfeed from "../allcomponent/Newsfeed";
import Client from "../allcomponent/Client";
import Country from "../allcomponent/Country";

function Home() {
    return (
        <>
          <Layout>
               <Slider></Slider>
               <Facilities></Facilities>
               <About></About>
               <Country></Country>
               <Course></Course>
               <Achivement></Achivement>
               <Registrationform></Registrationform>
               <Event></Event>
               <Newsfeed></Newsfeed>
          </Layout>
        </>
    )
}

export default Home