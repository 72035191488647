import { useState,useEffect,React } from "react";
import axios from 'axios';
import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import Inqueryform from "../allcomponent/Inqueryform";
	

function Applyform() {
	const [title, setTitle] = useState("Apply");
	const [subtitle, setSubtitle] = useState("");
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
        <>
          <Layout>
            <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
              <Inqueryform></Inqueryform>
          </Layout>
        </>
    )
}

export default Applyform