import { useState,useEffect,React } from "react";
import axios from 'axios';
import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import Inqueryform from "../allcomponent/Inqueryform";
import Registrationform from "../allcomponent/Registrationform";
	

function Registration() {
	const [title, setTitle] = useState("Registration");
	const [subtitle, setSubtitle] = useState("Registration Now.");
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
        <>
          <Layout>
            <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
              <Registrationform></Registrationform>
          </Layout>
        </>
    )
}

export default Registration