

import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Course() {

    axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
      const [courselist, setCourselist] = useState();
      useEffect(() => {
          axios.get('/api/course')
          .then(response => {
            setCourselist(response.data.data.data);
          })
          .catch(error => {
              console.error(error);
          });

          window.scrollTo(0, 0);
          
      }, []);

      const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }

  return (  
    <div className="course-area bg-img pt-130" style={{backgroundImage:'url(assets/img/background2.jpg)'}}>
      <div className="container">
        <div className="section-title mb-30">
          <h2>
            <span>Featured</span> Courses
          </h2>
          <p>We Provide assistance to choose roght course according  <br />to your academic profile. </p>
        </div>
        <div className="course-slider coursesliderarea">
        { courselist &&
          <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
          {courselist?.map((course) => (
            <div className="single-course item">
              <div className="course-img">
                <Link key={course.id} to={`/coursedetails/${course.id}`}>
                  <img className="animated" src={course._document} alt="" height="250px"/>
                </Link>
              </div>
              <div className="course-content">
                <h4>
                  <Link key={course.id} to={`/coursedetails/${course.id}`}>{course._title}</Link>
                </h4>
                <p>{course._subtitle}.</p>
              </div>
              <div className="course-position-content">
                <div className="credit-duration-wrap">
                  <div className="sin-credit-duration">
                    <i className="fa fa-diamond" />
                    <span>Requirements: {course._credit}</span>
                    <br></br>
                    <i className="fa fa-clock-o" />
                    <span>Duration : {course._duration}</span>
                  </div>
                </div>
                <div className="course-btn">
                  <Link key={course.id} className="default-btn" to="/applyform">APPLY NOW</Link>
                </div>
              </div>
            </div>
            ))}
          </OwlCarousel>
        }
        </div>
      </div>
    </div> 
  );
}
export default Course;


