import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {
  const [logo, setLogo] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [menulist, setMenulist] = useState();
  const [submenulist, setSubmenulist] = useState();
  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  useEffect(() => {
      

      const allmenu = localStorage.getItem('menulist');

      if (allmenu) {
        setMenulist(JSON.parse(allmenu));
        setLogo(localStorage.getItem('logo'));
        setPhone(localStorage.getItem('phone'));
        setEmail(localStorage.getItem('email'));

      }else {

        axios.get('/api/menu')
          .then(response => {
            console.log(response);
              localStorage.setItem('menulist',JSON.stringify(response.data.data.data));
              setMenulist(response.data.data.data);
          })
          .catch(error => {
              console.error(error);
          });

          company();

      }
  }, []);

  function company(){
    axios.get('/api/companysetup')
          .then(response => {
            setLogo(response.data.data[0]._image);
            setPhone(response.data.data[0]._phone);
            setEmail(response.data.data[0]._email);
            setDescription(response.data.data[0]._description);
            localStorage.setItem('logo',(response.data.data[0]._image));
            localStorage.setItem('phone',(response.data.data[0]._phone));
            localStorage.setItem('email',(response.data.data[0]._email));
            localStorage.setItem('description',(response.data.data[0]._description));
          })
          .catch(error => {
              console.error(error);
          });
      
  }

  function submenu(id){

    axios.get(`/api/submenu/${id}`)
      .then(response => {
          
          setSubmenulist(response.data.data);  
          
      })
      .catch(error => {
          console.error(error);
      });
      return (submenulist?.map((submenu) => (
                <li><Link to={submenu._url}> {submenu._title} </Link></li>
              
           )))
     
  }


  return (
      <header className="header-area">
        <div className="header-top bg-img" style={{backgroundImage: 'url(assets/img/icon-img/header-shape.png)'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7 col-12 col-sm-8">
                <div className="header-contact">
                  <ul>
                    <li><i className="fa fa-phone" /> {phone}</li>
                    <li><i className="fa fa-envelope-o" />{email}</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-12 col-sm-4">
                <div className="login-register">
                  <ul>
                    {/*<li><Link to="https://admin.edumatric.com/app/login">Login</Link></li>
                    //<li><Link to="https://admin.edumatric.com/app/registration">Register</Link></li>
                    */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-4">
                <div className="logo">
                  <Link to="/">
                    <img alt="" src={logo} style={{height:"70px"}}/>
                  </Link>
                </div>
              </div>
              <div className="col-lg-10 col-md-6 col-8 text-right">
                
                <Navbar expand="lg" className="bg-body-tertiary">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                    {menulist?.map((menu) => (
                      <Link key={menu.id} style={{float:"right",padding:"15px",marginTop:"10px",fontSize:"16px",fontWeight:"bold"}} to={menu._url}>{menu._title}</Link>
                      ))}
                      
                    </Nav>
                  </Navbar.Collapse> 
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}
export default Header;