import { useState,useEffect,React } from "react";


import axios from 'axios';


import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import Event from "../allcomponent/Event";
import Client from "../allcomponent/Client";
import Achivement from "../allcomponent/Achivement";



function Eventpage() {
  const [title, setTitle] = useState("Event");
  const [subtitle, setSubtitle] = useState("We are organizing Education Expo every year.Join our education expo & get latest information.");
    return (
      <>
        <Layout>
          <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
          <Event></Event>
          <Achivement></Achivement>
          <Client></Client>
        </Layout>
      </>
    )
  }

export default Eventpage