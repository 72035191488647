import { Link } from "react-router-dom";
import React, { useState, useEffect, } from 'react';
import axios from 'axios';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Slider() {
  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  const [sliderlist, setSliderlist] = useState("");
   const [loading, setLoading] = useState("2");

    useEffect(() => {
      
      const sliderData = localStorage.getItem('slider');

      if (sliderData) {
        setSliderlist(JSON.parse(sliderData));
      }else{
        axios.get('/api/slider')
          .then(response => {

            
            localStorage.setItem('slider', JSON.stringify(response.data.data.data));
            //console.log(response);
                setSliderlist(response.data.data.data);
              })
              .catch(error => {
                  console.error(error);
              });
      }
      

    }, []);
  

  return (
        <>
          { sliderlist &&

          <OwlCarousel className='owl-theme' loop items={1} autoplay={true}>
          {sliderlist?.map((slider) => (
                <div className="item single-slider slider-height-1 bg-img" style={{backgroundImage: `url(${slider._image})`}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-9 col-md-7 col-12 col-sm-12">
                        <div className="slider-content slider-animated-1 pt-230">
                          <h1 className="animated">{slider._title}</h1>
                          <p className="animated">{slider._subtitle}</p>
                          <div className="slider-btn">
                            <Link  className="animated default-btn btn-green-color" to="/about">ABOUT US</Link>
                            <Link className="animated default-btn btn-white-color" to="/contact">CONTACT US</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
          </OwlCarousel>
        }
        </>
  );
}
export default Slider;