import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Newsfeed() {

  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  const [newslist, setNewslist] = useState();
    
    useEffect(() => {
        axios.get(`/api/newsfeed`)
        .then(response => {
          setNewslist(response.data.data.data);

          console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
        window.scrollTo(0, 0);
        
    }, []);

  const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 3,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }

  return (
    <div className="blog-area pt-70 pb-100"  style={{backgroundImage: 'url(assets/img/background5.jpg)'}}>
        <div className="container">
          <div className="section-title mb-75">
            <h2>Our <span>Newsfeed</span></h2>
            <p>Get the latest news from our newsfeeds<br />We are providing latest news for international student.</p>
          </div>
          <div className="row">
            {newslist && 
              <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
                
                {newslist?.map((news) =>(
                <div className="single-blog mb-30">
                  <div className="blog-img">
                    <a href="#"><img src={news._image} alt="" /></a>
                  </div>
                  <div className="blog-content-wrap">
                    <span>Education</span>
                    <div className="blog-content">
                      <h4><Link to="#">{news._title}</Link></h4>
                      <p>{news._subtitle}</p>
                    </div>
                    <div className="blog-date">
                      <a href="#"><i className="fa fa-calendar-o" /> {news._date.split(' ')[0]}</a>
                    </div>
                  </div>
                </div>
                ))}
              </OwlCarousel>
            }
          </div>
        </div>
      </div>
  );
}
export default Newsfeed;




