
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modalmain from "../allcomponent/Modalmain";
function About() {

	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState('');

	  const openModal = () => {
	    setShowModal(true);
	  };

	  const closeModal = () => {
	    setShowModal(false);
	  };
	axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  	const [sectionlist, setSectionlist] = useState();
	const url = "about";
	useEffect(() => {
	      axios.get(`/api/section/${url}`)
	      .then(response => {
	        setSectionlist(response.data.data.data);
	      })
	      .catch(error => {
	          console.error(error);
	      });

        window.scrollTo(0, 0);
	      
	  }, []);

  return (    
    <div className="about-us pt-10 pb-70">
    	{sectionlist?.map((section) => (
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="about-content">
                <div className="section-title section-title-green mb-30">
                  <h2>{section._title}</h2>
                  <p>{section._subtitle}</p>

                </div>
                <p>{section._description}</p>
                <div className="about-btn mt-45">
                  <Link className="default-btn" to={section._link}>ABOUT US</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-img default-overlay">
                <img src={section._image} alt="" />
                <a className="video-btn video-popup" onClick={openModal}>
                  <img className="animated" src="assets/img/icon-img/video.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <Modalmain showModal={showModal} closeModal={closeModal} modalContent={section._videourl}></Modalmain>
        </div>
        
        ))}
      </div>
  );
}
export default About;


