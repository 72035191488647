import { useState,useEffect,React } from "react";


import axios from 'axios';


import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
    const [title, setTitle] = useState("Contact Us");
    const [subtitle, setSubtitle] = useState("");

    const [phone, setPhone] = useState();
    const [address, setAddress] = useState();
    const [email, setEmail] = useState();
    axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
    useEffect(() => {
        setPhone(localStorage.getItem('phone'));
        setAddress(localStorage.getItem('description'));
        setEmail(localStorage.getItem('email'));
        window.scrollTo(0, 0);
    }, []);


    const [status, setStatus] = useState("1");
    const handleSubmit = (event) => {
          event.preventDefault()
          const formData = new FormData(event.currentTarget);
          formData.append('status', status);
          axios.post('/api/studentregistration/add', formData).then(function(response){
              if(response.data.errors){
                  toast(response.data.message);
              }else{
                  toast("Registration Successful");
              }

             // navigate("/app/dashboard"); 
          }).catch(function (error) {
              console.log(error.message);
              toast("hello");
          });
      }
    return (
        <>
            <Layout>
            <ToastContainer />
                <div>
                <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
                <div className="contact-area pt-50 pb-50">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="contact-form">
                          <div className="contact-title">
                            <h2><span>Address</span></h2>
                             <div className="contact-info-icon">
                                <p><i className="fa fa-map-marker" /> {address}</p>
                                <p><span><i className="fa fa-phone" /></span> {phone}.</p>
                                <p><i className="fa fa-envelope" /> {email}</p>
                              </div>
                          </div> 
                        </div>
                        {/*<div className="contact-map mr-70">
                          <div id="map">
                            
                          </div> 
                        </div>*/}
                      </div>
                      <div className="col-lg-5">
                        <div className="contact-form">
                          <div className="contact-title">
                            <h2>Stay<span>Connected</span></h2>
                            <p></p>
                          </div>
                            <form onSubmit={handleSubmit} id="contact-form">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="contact-form-style">
                                    <input name="firstname" placeholder="First Name" type="text" />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="contact-form-style">
                                    <input name="lastname" placeholder="Last Name" type="text" />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="contact-form-style">
                                    <input name="phone" placeholder="Phone" type="text" />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="contact-form-style">
                                    <input name="email" placeholder="Email" type="text" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="contact-form-style">
                                    <textarea name="message" placeholder="Message" defaultValue={""} />
                                    <button className="submit default-btn" type="submit">SUBMIT NOW</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          <p className="form-messege" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="contact-info-area bg-img pt-180 pb-140 default-overlay" style={{backgroundImage: 'url(assets/img/background3.jpg)'}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-contact-info mb-30 text-center">
                          <div className="contact-info-icon">
                            <span><i className="fa fa-phone" /></span>
                          </div>
                          <p>{phone}.</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-contact-info mb-30 text-center">
                          <div className="contact-info-icon">
                            <span><i className="fa fa-map-marker" /></span>
                          </div>
                          <div className="contact-info-phn">
                            <div className="info-phn-number">
                              <p>{address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-contact-info mb-30 text-center">
                          <div className="contact-info-icon">
                            <span><i className="fa fa-envelope" /></span>
                          </div>
                          <a href="#">{email}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}  
              </div> 
            </Layout>
        </>
    )
}

export default Contact