
import React, { useState, useEffect } from 'react';

import Layout from "../include/Layout";
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link ,useParams} from 'react-router-dom';
import Breadcrumb from "../allcomponent/Breadcrumb";
import Client from "../allcomponent/Client";
import Achivement from "../allcomponent/Achivement";
function Universitycoursepage() {
    const params = useParams();
    axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
    const [graduatecourse, setGraduatecourse] = useState([]);
    const [undergraduatecourse, setUndergraduatecourse] = useState([]);
    const [diplomacourse, setDiplomacourse] = useState([]);
    const [title, setTitle] = useState(`${params.universityname}`);
    const [subtitle, setSubtitle] = useState("");
    
    useEffect(() => {
        graduate();
        undergraduate();
        diploma();
        window.scrollTo(0, 0);
    }, []);


    function graduate(){
      axios.get(`/api/graduatecourse/${params.id}`)
      .then(response => {
        setGraduatecourse(response.data.data); 
      })
      .catch(error => {
          console.error(error);
      });
    }

    function undergraduate(){
      axios.get(`/api/undergraduatecourse/${params.id}`)
      .then(response => {
        setUndergraduatecourse(response.data.data); 
      })
      .catch(error => {
          console.error(error);
      });
    }

    function diploma(){
      axios.get(`/api/diplomacourse/${params.id}`)
      .then(response => {
        setDiplomacourse(response.data.data); 
      })
      .catch(error => {
          console.error(error);
      });
    }

    const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }

  return (  
    <Layout>
        <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
        { graduatecourse.length > 0 &&
        <div className="course-area bg-img pt-90">
        <div className="container">
          <div className="section-title mb-40 course-mrg-small">
            <h2> Graduate <span>Courses</span></h2>
            <p>All Graduate Courses</p>
          </div>
          <div className="course-slider-active-4">
          <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>

          {graduatecourse?.map((graduate_course) => (
            <div className="single-course item">
              <div className="course-img">
                <Link to={`/coursedetails/${graduate_course.id}`}>
                  <img className="animated" src={graduate_course._document} alt="" height="250px"/>
                </Link>
              </div>
              <div className="course-content">
                <h4>
                  <Link to={`/coursedetails/${graduate_course.id}`}>{graduate_course._title}</Link>
                </h4>
                <p>{graduate_course._subtitle}.</p>
              </div>
              <div className="course-position-content">
                <div className="credit-duration-wrap">
                  <div className="sin-credit-duration">
                    <i className="fa fa-diamond" />
                    <span style={{fontSize:"11px"}}>Requirements: {graduate_course._credit}</span>
                    <br></br>
                    <i className="fa fa-clock-o" />
                    <span>Duration : {graduate_course._duration}</span>
                  </div>
                </div>
                <div className="course-btn">
                  <Link className="default-btn" to="/applyform">APPLY NOW</Link>
                </div>
              </div>
            </div>
            ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    }

    { undergraduatecourse.length > 0 &&
      <div className="course-area bg-img pt-70">
        <div className="container">
          <div className="section-title mb-40 course-mrg-small">
            <h2> <span>Undergraduate</span> Courses</h2>
            <p>All Undergraduate Courses</p>
          </div>
          <div className="course-slider-active-3">
          <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
            
            {undergraduatecourse?.map((undergraduate_course) => (
            <div className="single-course item">
              <div className="course-img">
                <Link to={`/coursedetails/${undergraduate_course.id}`}>
                  <img className="animated" src={undergraduate_course._document} alt="" height="250px"/>
                </Link>
              </div>
              <div className="course-content">
                <h4>
                  <Link to={`/coursedetails/${undergraduate_course.id}`}>{undergraduate_course._title}</Link>
                </h4>
                <p>{undergraduate_course._subtitle}.</p>
              </div>
              <div className="course-position-content">
                <div className="credit-duration-wrap">
                  <div className="sin-credit-duration">
                    <i className="fa fa-diamond" />
                    <span style={{fontSize:"11px"}}>Requirements: {undergraduate_course._credit}</span>
                    <br></br>
                    <i className="fa fa-clock-o" />
                    <span>Duration : {undergraduate_course._duration}</span>
                  </div>
                </div>
                <div className="course-btn">
                  <Link className="default-btn" to="/applyform">APPLY NOW</Link>
                </div>
              </div>
            </div>
            ))}
            
            </OwlCarousel>
          </div>
        </div>
      </div>
    }

    { diplomacourse.length > 0 &&
      <div className="course-area bg-img pt-30">
        <div className="container">
          <div className="section-title mb-40 course-mrg-small">
            <h2> <span>Diploma</span> Courses</h2>
            <p>All Diploma Courses</p>
          </div>
          <div className="course-slider-active-5">
          <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
            {diplomacourse?.map((diploma_course) => (
            <div className="single-course item">
              <div className="course-img">
                <Link to={`/coursedetails/${diploma_course.id}`}>
                  <img className="animated" src={diploma_course._document} alt="" height="250px"/>
                </Link>
              </div>
              <div className="course-content">
                <h4>
                  <Link to={`/coursedetails/${diploma_course.id}`}>{diploma_course._title}</Link>
                </h4>
                <p>{diploma_course._subtitle}.</p>
              </div>
              <div className="course-position-content">
                <div className="credit-duration-wrap">
                  <div className="sin-credit-duration">
                    <i className="fa fa-diamond" />
                    <span style={{fontSize:"11px"}}>Requirements: {diploma_course._credit}</span>
                    <br></br>
                    <i className="fa fa-clock-o" />
                    <span>Duration : {diploma_course._duration}</span>
                  </div>
                </div>
                <div className="course-btn">
                  <Link className="default-btn" to="/applyform">APPLY NOW</Link>
                </div>
              </div>
            </div>
            ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
      }
    </Layout> 
  );
}
export default Universitycoursepage;


