
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';


function Facilities() {

  return (
           
    <div className="choose-us section-padding-1">
      <div className="container-fluid">
        <div className="row no-gutters choose-negative-mrg">
          <div className="col-lg-3 col-md-6">
            <div className="single-choose-us choose-bg-light-blue">
              <div className="choose-img">
                <img className="animated" src="assets/img/icon-img/service-1.png" alt="" />
              </div>
              <div className="choose-content">
                <h3>Free Assessment</h3>
                <p>In Edumatric, We Provide free assessment to all students. </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-choose-us choose-bg-yellow">
              <div className="choose-img">
                <img className="animated" src="assets/img/icon-img/service-2.png" alt="" />
              </div>
              <div className="choose-content">
                <h3>Free Application</h3>
                <p>We provide free application support to all destinations. </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-choose-us choose-bg-blue">
              <div className="choose-img">
                <img className="animated" src="assets/img/icon-img/service-3.png" alt="" />
              </div>
              <div className="choose-content">
                <h3>Visa Processing</h3>
                <p>We provide visa processing & logistic support. </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-choose-us choose-bg-green">
              <div className="choose-img">
                <img className="animated" src="assets/img/icon-img/service-4.png" alt="" />
              </div>
              <div className="choose-content">
                <h3>Language Support</h3>
                <p>We provide IELTS & PTE exam guideline to all students. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Facilities;