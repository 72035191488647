
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Event() {


  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  const [eventlist, setEventlist] = useState();
    
    useEffect(() => {
        axios.get(`/api/event`)
        .then(response => {
          setEventlist(response.data.data.data);

          console.log(response);
        })
        .catch(error => {
            console.error(error);
        });

        window.scrollTo(0, 0);
        
    }, []);

  const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 3,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }

  return (
           
    <div className="event-area bg-img default-overlay pt-70 pb-70">
        <div className="container">
          <div className="section-title mb-75">
            <h2><span>Our</span> Event</h2>
            <p>We are organizing Education Expo every year.<br />Join our education expo & get latest information. </p>
          </div>
          <div className="course-slider-active-5">
          {eventlist && 
          <OwlCarousel className='owl-theme' nav responsive={state.responsive} margin={10}>
            
          {eventlist?.map((event) => (
            <div className="single-event event-white-bg item" key={event.id}>
              <div className="event-img">
                <a href="#"><img src={event._image} alt="" /></a>
                <div className="event-date-wrap">
                  <span>{event._date.split(' ')[0]}</span>
                </div>
              </div>
              <div className="event-content" style={{marginBottom:"20px"}}>
                <h3><a href="#">{event._title}.</a></h3>
                <p>{event._subtitle}</p>
                <div className="event-meta-wrap">
                  <div className="event-meta">
                    <i className="fa fa-location-arrow" />
                    <span>{event._location}</span>
                  </div>
                  <div className="event-meta">
                    <i className="fa fa-clock-o" />
                    <span>{event._time}</span>
                  </div>
                </div>
              </div>
            </div>
             ))}
            </OwlCarousel>
            }
          </div>
        </div>
      </div>
  );
}
export default Event;



