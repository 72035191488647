import React, { useState, useEffect } from 'react';
import { Button,Modal } from 'react-bootstrap';


export default function Modalmain({showModal,closeModal,modalContent}) {
  
  return (
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeModalButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <iframe className="embed-responsive-item" src={modalContent} allowfullscreen width="100%" height="500px"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
  );
}