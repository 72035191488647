import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Diplomacourse() {


  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
      const [courselist, setCourselist] = useState();
      useEffect(() => {
          axios.get('/api/dcourse')
          .then(response => {

            console.log(response);
            setCourselist(response.data.data);
          })
          .catch(error => {
              console.error(error);
          });
          
      }, []);

      const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }
  
  return (
        <div className="course-area bg-img">
        <div className="container">
          <div className="section-title mb-40 course-mrg-small">
            <h2> <span>Diploma</span> Courses</h2>
            <p>All Diploma Courses</p>
          </div>
          <div className="course-slider-active-5">
          { courselist &&
            <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
            {courselist?.map((course) => (
              <div className="single-course item">
              <div className="course-img">
                <Link to={`/coursedetails/${course.id}`}>
                  <img className="animated" src={course._document} alt="" height="250px"/>
                </Link>
              </div>
              <div className="course-content">
                <h4>
                  <Link to={`/coursedetails/${course.id}`}>{course._title}</Link>
                </h4>
                <p>{course._subtitle}.</p>
                <p><b>{course.universityname}, {course.countryname} </b></p>
              </div>
              <div className="course-position-content">
                <div className="credit-duration-wrap">
                  <div className="sin-credit-duration">
                    <i className="fa fa-diamond" />
                    <span style={{fontSize:"11px"}}>Requirements: {course._credit}</span>
                    <br></br>
                    <i className="fa fa-clock-o" />
                    <span>Duration : {course._duration}</span>
                  </div>
                </div>
                <div className="course-btn">
                  <Link className="default-btn" to="/applyform">APPLY NOW</Link>
                </div>
              </div>
            </div>
              ))}
            </OwlCarousel>
          }
          </div>
        </div>
      </div>
  );
}
export default Diplomacourse;

  

      