
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Client() {
const [clientlist, setClientlist] = useState();
  useEffect(() => {
      axios.get('/api/client')
      .then(response => {
        setClientlist(response.data.data.data);
      })
      .catch(error => {
          console.error(error);
      });
      
  }, []);

  return (    

    
   <div className="brand-logo-area pb-20">
      <div className="container">
        <div className="brand-logo-active">
          {clientlist && 
            <OwlCarousel className='owl-theme' loop items={8} margin={10}>
              {clientlist?.map((client) => (
              <div className="single-brand-logo">
                <Link to="#"><img src={client._image} alt="" /></Link>
              </div>
               ))}
            </OwlCarousel>
          }
      </div>
    </div>
  </div>
  );
}
export default Client;


