import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Footer() {
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [sociallink, setSociallink] = useState();
  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  useEffect(() => {

      const sociallinkdata = localStorage.getItem('sociallinklist');

      if (sociallinkdata) {
        setSociallink(JSON.parse(sociallinkdata));
        setPhone(localStorage.getItem('phone'));
        setDescription(localStorage.getItem('description'));
        setEmail(localStorage.getItem('email'));
      }
      
      else{
        axios.get('/api/sociallink')
          .then(response => {
              localStorage.setItem('sociallinklist',JSON.stringify(response.data.data.data));
              setSociallink(response.data.data.data);
          })
          .catch(error => {
              console.error(error);
          });
     
      }
  }, []);

  
  
  return (
    <footer className="footer-area">
        <div className="footer-top bg-img default-overlay pt-20 pb-20" style={{backgroundImage: 'url(assets/img/backgroundfooter.jpg)'}}>
          <div className="container">
            <div className="row"  style={{marginTop:"20px"}}>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget mb-10">
                  <div className="footer-title">
                    <h4>ABOUT US</h4>
                  </div>
                  <div className="footer-about">
                    <div className="f-contact-info">
                      <div className="single-f-contact-info">
                        <i className="fa fa-home" />
                        <span>{description}</span>
                      </div>
                      <div className="single-f-contact-info">
                        <i className="fa fa-envelope-o" />
                        <span><Link to="#">{email}</Link></span>
                      </div>
                      <div className="single-f-contact-info">
                        <i className="fa fa-phone" />
                        <span> {phone}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-1"></div> 
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-widget mb-40">
                  <div className="footer-title">
                    <h4>QUICK LINK</h4>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/about">About Us</Link></li>
                      <li><Link to="/contact">Contact</Link></li>
                      <li><Link to="/applyform">Registration</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
               <div className="col-lg-1 col-md-1"></div> 
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-widget negative-mrg-30 mb-40">
                  <div className="footer-title">
                    <h4>COURSES</h4>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li><Link to="#">Under Graduate Programmes </Link></li>
                      <li><Link to="#">Graduate Programmes </Link></li>
                      <li><Link to="#">Diploma Courses</Link></li>
                      <li><Link to="#">Others Programmes</Link></li>
                      <li><Link to="#">Short Courses</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom pt-15 pb-15">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12">
                <div className="copyright">
                  <p>
                    Copyright ©
                    <Link to="https://edumatric.com/">EDUMATRIC</Link>
                    . All Right Reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="footer-menu-social">
                  <div className="footer-menu">
                    {/* <ul>
                      <li><Link to="/privacy-policy">Privecy &amp; Policy</Link></li>
                      <li><Link to="/terms-condition">Terms &amp; Conditions of Use</Link></li>
                    </ul> */}
                  </div>
                  <div className="footer-social">
                    <ul>
                    	{sociallink?.map((e) => (
                
			                <li><Link key={e.id} to={e._url}><i className={e._title} /></Link></li>
			                
			               ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
}
export default Footer;