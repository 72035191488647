

import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CountUp from 'react-countup';
function Achivement() {

  axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
  const [reviewlist, setReviewlist] = useState();
  useEffect(() => {
      axios.get('/api/review')
      .then(response => {
        setReviewlist(response.data.data.data);
      })
      .catch(error => {
          console.error(error);
      });
      
  }, []);

  const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    }


  return (
           
    <div className="achievement-area mt-60 pb-20">
        <div className="container">
          <div className="section-title mb-75">
            <h2>Our <span>Achievement</span></h2>
            <p>Every year more then thousands students are processing to USA, CANADA, AUSTRALIA, UK, EUROPE, MALAYSIA & CHINA.</p>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="single-count mb-30 count-one">
                <div className="count-img">
                  <img src="/assets/img/icon-img/achieve-1.png" alt="" />
                </div>
                <div className="count-content">
                  <h2 className="count"><CountUp end={5890} delay={3}/> </h2>
                  <span>STUDENTS</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="single-count mb-30 count-two">
                <div className="count-img">
                  <img src="/assets/img/icon-img/achieve-2.png" alt="" />
                </div>
                <div className="count-content">
                  <h2 className="count"><CountUp end={4970} delay={3}/></h2>
                  <span>GRADUATE</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6">
              <div className="single-count mb-30 count-three">
                <div className="count-img">
                  <img src="/assets/img/icon-img/achieve-3.png" alt="" />
                </div>
                <div className="count-content">
                  <h2 className="count"><CountUp end={2000} delay={3}/></h2>
                  <span>Universities</span>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <div className="single-count mb-30 count-four">
                <div className="count-img">
                  <img src="/assets/img/icon-img/achieve-4.png" alt="" />
                </div>
                <div className="count-content">
                  <h2 className="count"><CountUp end={20} delay={3}/></h2>
                  <span>Countries</span>
                </div>
              </div>
            </div>
          </div>
          

          
            <div className="testimonial-slider-wrap mt-45">
            <OwlCarousel className='owl-theme' nav loop responsive={state.responsive}>
            {reviewlist?.map((review) => (
            <div className="testimonial-text-slider item">
              <div className="row">
                <div className="col-lg-6 col-md-12" style={{paddingRight:"0px"}}>
                  <img alt="" src={review._image} height="100%"/>
                </div>
                  <div className="ms-auto col-lg-6 col-md-12" style={{margin:"0",padding:"0"}}>
                    <div className="testi-content bg-img default-overlay" style={{padding:"20px"}}>
                      <div className="quote-style quote-left">
                        <i className="fa fa-quote-left" />
                      </div>
                      <p>{review._description} </p>
                      <div className="testi-info">
                        <h5>{review._title}</h5>
                        <span>{review._subtitle}</span>
                      </div>
                      <div className="quote-style quote-right">
                        <i className="fa fa-quote-right" />
                      </div>
                      <div className="testi-arrow">
                        <img alt="" src="/assets/img/icon-img/testi-icon.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               ))}
             </OwlCarousel>
          </div>
          <div className="testimonial-text-img">
            <img alt="" src="/assets/img/icon-img/testi-text.png" />
          </div>
        </div>
      </div>
  );
}
export default Achivement;





