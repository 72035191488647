import { useState,useEffect,React } from "react";


import axios from 'axios';


import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import Videocontent from "../allcomponent/Videocontent";
import Client from "../allcomponent/Client";
import Achivement from "../allcomponent/Achivement";

	

function Aboutus() {
 

	const [title, setTitle] = useState("About Us");
	const [subtitle, setSubtitle] = useState("EduMatric is a Bangladesh Based prominent education consultancy company providing support to students who are keen to Study in the USA, Canada, Australia, The UK, Europe, Malaysia and China.");
    
    return (
        <>
            <Layout>
              <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
              {/* <Videocontent></Videocontent> */}
              <Achivement></Achivement>
              <Client></Client>
            </Layout>
        </>
    )
}

export default Aboutus