import { useState,useEffect,React } from "react";
import axios from 'axios';
import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import Graduatecourse from "../allcomponent/Graduatecourse";
import Undargraduatecourse from "../allcomponent/Undargraduatecourse";
import Diplomacourse from "../allcomponent/Diplomacourse";


function Courses() {
    const [title, setTitle] = useState("Courses");
    const [subtitle, setSubtitle] = useState("Search the courses according to your academic profile and language skill.Open intakes are shown in the below list.");
    
    return (
        <>
            <Layout>
              <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
              <Undargraduatecourse></Undargraduatecourse>
              <Graduatecourse></Graduatecourse>
              <Diplomacourse></Diplomacourse>
            </Layout>
        </>
    )
}

export default Courses