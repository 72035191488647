import { useState,useEffect,React } from "react";
import axios from 'axios';
import Layout from "../include/Layout";
import Breadcrumb from "../allcomponent/Breadcrumb";
import Relatedcourse from "../allcomponent/Relatedcourse";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link ,useParams} from 'react-router-dom';
function Coursedetails() {

  const params = useParams();
    axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
    const [courselist, setCourselist] = useState();
    const [universityid, setUniversityid] = useState();
    const [title, setTitle] = useState("Course Details");
    const [subtitle, setSubtitle] = useState("You can find course information easily form our website.");
    useEffect(() => {
        axios.get(`/api/coursedetails/${params.id}`)
        .then(response => {
          setCourselist(response.data.data);
          setUniversityid(response.data.data._universityid);
        })
        .catch(error => {
            console.error(error);
        });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
          <Layout>
          <Breadcrumb title={title} subtitle={subtitle}></Breadcrumb>
              <div className="course-details-area pt-50">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-9 col-lg-8">
                      <div className="course-left-wrap mr-40">
                        <div className="course-tab-list nav pb-25 mb-35">
                          <a className="active" href="#course-details-1" data-bs-toggle="tab">
                            <h4>Over View</h4>
                          </a>
                          <a href="#course-details-2" data-bs-toggle="tab">
                            <h4>FAQ </h4>
                          </a>
                          
                        </div>
                        

                        <div className="tab-content jump">
                          <div className="tab-pane active" id="course-details-1">
                            <div className="over-view-content">

                              <h4>COURSE  DETAILS <p><b>{courselist?.universityname}, {courselist?.countryname} </b></p></h4>
                              <h5>Course : {courselist?._title}</h5>

                              <p>{courselist?._description}</p>

                              <div className="course-details-img">
                                <img src="assets/img/banner/course-details-1.jpg" alt="" />
                              </div>
                              <div className="course-summary-wrap">
                                <div className="single-course-summary">
                                  <h4>Course Duration</h4>
                                  <span><i className="fa fa-clock-o" />{courselist?._duration}</span>
                                </div>
                                <div className="single-course-summary">
                                  <h4>Requirements</h4>
                                  <span><i className="fa fa-diamond" /> {courselist?._credit}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane" id="course-details-2">
                            <div className="review-wrapper">
                              <div className="single-review">
                                <div className="review-content">
                                  <div className="review-top-wrap">
                                    <div className="review-left">
                                      <div className="review-name">
                                        <h4>White Lewis</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="review-bottom">
                                    <p>Vestibulum ante ipsum primis aucibus orci luctustrices posuere cubilia Curae Suspendisse viverra ed viverra. Mauris ullarper euismod vehicula. Phasellus quam nisi, congue id nulla nec, convallis conval lis leo. Maecenas bibendum bibendum larius.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*
                      {universityid ? (
                          // Pass the ID to the child component
                          <Relatedcourse universityid={universityid}></Relatedcourse>
                        ) : (
                          <p>Loading...</p>
                        )}
                      */}
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <div className="sidebar-style sidebar-res-mrg-none">
                        <div className="sidebar-category mb-40">
                          <div className="sidebar-title mb-40">
                            <h4>Additional Details</h4>
                          </div>
                          <div className="category-list">
                            <ul>
                              <li><a href="#">Cost of Living <b>{courselist?._livingcost}</b></a></li>
                              <li><a href="#">Gross Tuition<b>{courselist?._tutionfees}</b></a></li>
                              <li><a href="#">Application Fee<b>{courselist?._applicationfees}</b></a></li>
                              
                            </ul>
                          </div>
                        </div>
                        <div className="sidebar-category mb-40">
                          <div className="sidebar-title mb-40">
                            <h4>Intake</h4>
                          </div>
                          <div className="category-list">
                            <ul>
                              <li><a href="#"><b>{courselist?._programintake}</b></a></li>
                              <li><a href="#">Open Date <b>{courselist?._programintakeopendate.split(' ')[0]}</b></a></li>
                              <li><a href="#">Submission deadline <b>{courselist?._programintakedeadline.split(' ')[0]}</b></a></li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
          </Layout>
        </>
    )
}

export default Coursedetails