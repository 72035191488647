
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';


function Breadcrumb({title,subtitle}) {
  return (
           
    <div className="breadcrumb-area">
        <div className="breadcrumb-top default-overlay bg-img breadcrumb-overly-2 pt-100 pb-95" style={{backgroundImage: 'url(https://edumatric.com/assets/img/breadcumb.jpg)'}}>
          <div className="container">
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </div>
        {/*<div className="breadcrumb-bottom">
          <div className="container">
            <ul>
              <li><Link to="/">Home</Link> <span><i className="fa fa-angle-double-right" />{title}</span></li>
            </ul>
          </div>
        </div>*/}
      </div>
  );
}
export default Breadcrumb;


