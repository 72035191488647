

import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Country() {

    axios.defaults.baseURL = 'https://admin.sicgroup.ca/';
      const [countrylist, setCountrylist] = useState();
      useEffect(() => {
          axios.get('/api/country')
          .then(response => {
            setCountrylist(response.data.data.data);
          })
          .catch(error => {
              console.error(error);
          });
          
          
      }, []);



      const state= {
        responsive:{
            0: {
                items: 2,
            },
            450: {
                items: 3,
            },
            600: {
                items: 4,
            },
            1000: {
                items: 5,
            },
        },
    }

  return (  
    <div className="course-area bg-img pb-10">
      <div className="container">
        <div className="section-title mb-75">
          <h2>
            <span>Choose Your Country</span>
          </h2>
          <p>We are working with 2000+ universities of 20+ countries. </p>
        </div>
        <div className="course-slider countrylist">
        {countrylist && 
          <OwlCarousel className='owl-theme' nav loop responsive={state.responsive} margin={10}>
          {countrylist?.map((country) => (
            <div className="single-course item">
              <div className="course-img">
                <Link to={`/country/${country.id}/${country._name}`} key={country.id}>
                  <img className="animated" src={country._image} alt="" />
                </Link>
              </div>
              <div className="course-content">
                <h4>
                  <Link to={`/country/${country.id}`} key={country.id}>{country._name}</Link>
                </h4>
              </div>
            </div>
            ))}
          </OwlCarousel>
          }
        </div>
      </div>
    </div> 
  );
}
export default Country;


